<template>
  <div
    id="signup"
    style="overflow: hidden; width: 100%; height: 100vh"
    class="d-flex align-center"
    :class="$vuetify.breakpoint.mobile ? 'px-10' : 'px-15'"
  >
    <v-form
      v-if="showform"
      class="flex-grow-1"
      v-model="formvalid"
      :disabled="processing"
    >
      <div class="d-flex justify-center flex-column mb-5">
        <div
          class="primary--text mb-3"
          :style="
            $vuetify.breakpoint.mobile ? 'font-size: 7vw' : 'font-size: 2vw'
          "
        >
          {{ $t("AUTH.REGISTER.TITLE") }}
          <span class="font-weight-black">{{ $t("GENERAL.title") }}</span>
        </div>
      </div>
      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        filled
        rounded
        color="primary"
        :rules="nameRules"
        required
        dense
        single-line
        v-model="form.full_name"
        :label="$t('PROFILE.medical.fullname')"
        value=""
      />
      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        filled
        rounded
        class="mt-3"
        color="primary"
        :rules="phoneRules"
        required
        dense
        single-line
        v-model="form.phone"
        :label="$t('PROFILE.account.phone')"
        value=""
      />
      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        filled
        :disabled="checkingemail"
        ref="user_email"
        @blur="checkemail"
        class="mt-3"
        rounded
        color="primary"
        :rules="emailRules"
        required
        dense
        single-line
        v-model.trim="form.email"
        :label="$t('AUTH.LOGIN.EMAIL')"
        value=""
      >
        <template v-slot:append>
          <v-fade-transition leave-absolute>
            <v-progress-circular
              v-if="checkingemail"
              size="24"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-fade-transition>
        </template>
      </v-text-field>

      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        class="mt-3"
        type="password"
        :rules="[(v) => !!v || $t('AUTH.LOGIN.PASSVALIDATION')]"
        required
        filled
        rounded
        dense
        single-line
        color="primary"
        v-model="form.password"
        :label="$t('AUTH.LOGIN.PASSWORD')"
        value=""
      />

      <v-snackbar v-model="snackbar" multi-line timeout="5000" color="error">
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <div class="mt-5">
        <v-btn
          @click="submit"
          :disabled="!formvalid"
          :loading="processing"
          rounded
          elevation="0"
          block
          color="green darken-3"
          class="white--text"
          >{{ $t("AUTH.REGISTER.BUTTON") }}</v-btn
        >
      </div>
      <v-divider />
      <v-row class="my-0 py-0">
        <v-col cols="12" class="my-0 py-0">
          <router-link :to="{ name: 'login' }">
            <v-btn rounded block elevation="0" color="primary">{{
              $t("AUTH.LOGIN.BUTTON")
            }}</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-form>
    <div
      style="
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
      v-else
    >
      <lottie-animation
        style="height: 100px"
        :animationData="require('@/assets/animation/confetti.json')"
        :loop="true"
      />
      <div class="text-muted text-center">
        {{ $t("AUTH.REG_STEPS.actions.welcome", { name: form.first_name }) }}
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import { LOGIN } from "@/core/services/store/auth.module";

export default {
  name: "register",

  components: { LottieAnimation },
  data() {
    return {
      languageFlag: "",
      opendocreg: false,
      showformonly: false,
      initialheight: 0,
      snackbar: false,
      // Remove this dummy login info
      formvalid: false,
      emailRules: [
        (v) => !!v || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
        (v) => /.+@.+/.test(v) || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
      ],
      nameRules: [
        (v) => !!v || this.$t("AUTH.REG_STEPS.STEP1.validation[0]"),
        (v) => {
          const pattern = /^[^\s]+( [^\s]+)+$/;
          return (
            pattern.test(v) || this.$t("AUTH.REG_STEPS.STEP1.validation[1]")
          );
        },
      ],
      phoneRules: [
        (v) => !!v || this.$t("AUTH.REG_STEPS.STEP3.validation.0"),
        (v) =>
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(v) ||
          this.$t("AUTH.REG_STEPS.STEP3.validation.1"),
      ],
      submitted: false,
      processing: false,
      form: {
        full_name: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        timezone: this.$util.getTimezone(),
        role: 4,
      },
      error: "",
      checkingemail: false,
      showform: true,
    };
  },
  mounted() {},
  methods: {
    resetForm() {
      this.form = {
        full_name: "",
        password: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        timezone: this.$util.getTimezone(),
        role: 4,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async checkemail() {
      this.checkingemail = true;
      await this.$api
        .get("users?fields=id&filter[email]=" + this.form.email, "", true)
        .then((data) => {
          if (data.data.data.length > 0) {
            this.$refs.user_email.errorMessages.push(
              this.$t("AUTH.REG_STEPS.STEP2.validation.2")
            );
          }
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => (this.checkingemail = false));
    },
    async submit() {
      if (this.formvalid) {
        this.processing = true;
        // console.log("submitting", this.form);
        let splitname = this.form.full_name.split(" ");
        this.form.first_name = splitname[0];
        let regpayload = {
          first_name: splitname[0],
          last_name: splitname[splitname.length - 1],
          email: this.form.email,
          phone: this.form.phone.replace(" ", ""),
          password: this.form.password,
          timezone: this.form.timezone,
          role: this.form.role,
          status: "active",
        };
        let regresult = await this.$user.createUser2(regpayload);
        // console.log("reg result", regresult);
        if (regresult.status === 200) {
          this.login();
        } else {
          this.error = "Couldn't Register, try again in a bit. Thank you.";
          this.snackbar = true;
          this.processing = false;
        }
      }
    },
    async login() {
      let email = this.form.email;
      let password = this.form.password;
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(async () => {
          await this.$user.fetchCurrentUser().then(async () => {
            let user = this.$user.getCurrentUser();

            this.$util.newDS();
            this.$util.startUpDS(user);
            this.$user.logAccess(this.$util.getDeviceInfo());
            if (process.env.NODE_ENV === "production") {
              this.$rocket.identify(user.external_id, {
                name: user.first_name + " " + user.last_name,
                email: user.email,
              });
            }
            // create mf

            await this.$file.createItem(user).then((data) => {
              // console.log("new mf is", data.data.data);
              this.$root.mf = data.data.data.id;
              this.$file.data.currentFile = data.data.data;
              this.progress += 10;
            });

            // redirect
            this.processing = false;
            this.showform = false;
            setTimeout(() => {
              this.$root.currentpage = "dashboard";
              this.$router.push({ name: "dashboard" });
            }, 3000);
          });
        });
    },
  },
  watch: {},
  computed: {},
};
</script>
<style>
.v-application ul {
  padding-left: 0px !important;
}
.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  padding: 15px 18px !important;
  background-color: #f3f6f9 !important;
  color: rgb(63, 66, 84) !important;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot {
  background-color: rgb(232, 240, 254) !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  background-color: transparent !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input,
label {
  color: rgb(63, 66, 84) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f6f9 inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(232, 240, 254) inset;
}

.animate_ar_mobile {
  margin-right: auto;
  margin-left: auto;
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en_mobile {
  margin-right: auto;
  margin-left: auto;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
}
</style>
